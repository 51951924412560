// eslint-disable-next-line import/prefer-default-export
export const featuresSliderIE11Fix = `
  .slick-list .slick-slide {
    // targeting feature slider list items body text
    // add width definitions to fix IE11 flex issues
    div[class*='FeaturesSliderstyled__CardBody'] {
      width: 100%;
    }
  }
`;
