/* eslint-disable react/forbid-prop-types */
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@latitude/link';

import './_button.scss';

const Button = props => {
  const className = classnames(
    'button',
    { 'button--primary': props.primary },
    { 'button--secondary': props.secondary },
    { 'button--secondary-inverse': props.secondaryInverse },
    { 'button--tertiary': props.tertiary },
    { 'button--active': props.active },
    { 'button--wide': props.wide },
    { 'button--white-border': props.whiteBorder },
    { 'button--small': props.small },
    { 'button--fixed-width': props.fixedWidth },
    { 'button--fixed-width-md': props.fixedWidthMd },
    props.className
  );

  const newProps = {
    className,
    href: props.href,
    onClick: props.onClick,
    trackId: props.trackId,
    targetBlank: props.target === '_blank',
    target: props.target,
    trackEventData: props.trackEventData,
    trackProductId: props.trackProductId
  };

  return <Link {...newProps}>{props.children}</Link>;
};

Button.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  secondaryInverse: PropTypes.bool,
  tertiary: PropTypes.bool,
  wide: PropTypes.bool,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.node.isRequired,
  trackId: PropTypes.string.isRequired,
  trackEventData: PropTypes.object,
  trackProductId: PropTypes.arrayOf(PropTypes.string)
};

export default Button;
