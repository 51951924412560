import React from 'react';
import styled, { css } from 'styled-components';
import ReactHTMLParser from 'react-html-parser';
import { Heading3 } from '../Heading/Heading';
import Text from '../Text/Text';
import { BREAKPOINT, COLOR } from '../../utils/constants';
import Section from '../Section/Section';

const PromoContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${BREAKPOINT.LG}) {
    flex-direction: row;
  }
`;

const PromoFrameContainer = styled.div`
  ${({ bgColor }) => {
    return (
      bgColor &&
      css`
        background-color: ${bgColor};
      `
    );
  }};
  position: relative;
  width: 242px;
  height: 215px;
  margin: 0 auto 45px auto;
  ${({ alignment }) => {
    return alignment === 'left'
      ? css`
          transform: translateX(23px);
        `
      : css`
          transform: translateX(-23px);
        `;
  }};

  @media (min-width: ${BREAKPOINT.LG}) {
    width: 390px;
    height: 346px;
    transform: none;
    margin: ${({ alignment }) => {
      return alignment === 'left' ? '0 0 45px 45px' : '0 45px 45px 0';
    }};
    order: ${({ alignment }) => {
      return alignment === 'left' ? 1 : 2;
    }};
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
    margin: 0;

    @media (min-width: ${BREAKPOINT.LG}) {
      width: 100%;
      height: 100%;
    }
  }

  img {
    width: 242px;
    height: 215px;
    display: block;
    margin: 0;

    @media (min-width: ${BREAKPOINT.LG}) {
      width: 390px;
      height: 346px;
    }
  }

  &:before {
    display: block;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 45px;

    ${({ alignment }) => {
      return alignment === 'left'
        ? css`
            left: -45px;
          `
        : css`
            right: -45px;
          `;
    }};
    border: solid 30px
      ${({ borderColor }) => {
        return borderColor;
      }};
    z-index: -1;
  }
`;

const PromoButtons = styled.div`
  margin: 16px auto 0;
  .button {
    max-width: 156px;
    margin-right: 16px;

    ${({ alignment }) => {
      return (
        alignment === 'right' &&
        css`
          margin-left: auto;
        `
      );
    }};
  }

  a.pay-it-faster-promo-button,
  a.cta-min-side-padding {
    // IE11: reduced side padding fits longer text under fixed button width
    padding-left: 4px;
    padding-right: 4px;
  }

  > div {
    display: flex;
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    ${({ alignment }) => {
      return alignment === 'left'
        ? css`
            margin-left: 0;
            margin-right: 0;
          `
        : css`
            margin-left: auto;
            margin-right: 0;
            text-align: right;
          `;
    }};
  }
`;

const PromoContentContainer = styled.div`
  padding: 24px 0;
  text-align: center;

  ${({ verticalAlign }) => {
    return (
      verticalAlign &&
      css`
        display: flex;
        flex-direction: column;
        justify-content: center;

        // IE11: required for flex alignment
        @media (max-width: ${BREAKPOINT.LG_DOWN}) {
          align-items: center;
        }
      `
    );
  }}

  h3 {
    font-size: 32px;
    line-height: 1.13;
    margin-bottom: 16px;
    color: ${COLOR.BLACK};
    text-align: center;
    max-width: 100%; // IE11 requires max-width for flex bug

    @media (min-width: ${BREAKPOINT.LG}) {
      font-size: 40px;
      line-height: 1.2;
      text-align: ${({ alignment }) => {
        return alignment === 'left' ? 'left' : 'right';
      }};
    }
  }

  p {
    color: ${COLOR.GREY75};
    text-align: center;
    max-width: 100%; // IE11 requires max-width for flex bug

    @media (min-width: ${BREAKPOINT.SM}) {
      max-width: 320px;
      margin: 0 auto;
    }

    @media (min-width: ${BREAKPOINT.LG}) {
      margin: 0;
      max-width: 420px;
      text-align: ${({ alignment }) => {
        return alignment === 'left' ? 'left' : 'right';
      }};
    }
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    padding: 32px;
    text-align: left;
    order: ${({ alignment }) => {
      return alignment === 'left' ? 2 : 1;
    }};
  }

  ${({ customStyles }) => {
    return customStyles || '';
  }}
`;

const Promo = ({
  frameImage,
  description,
  title,
  frameAlignment,
  frameBorderColor,
  frameBackgroundColor,
  verticalAlignContent,
  contentContainerStyles,
  ctaButtons,
  footerText
}) => {
  return (
    <PromoContainer>
      {frameImage && (
        <PromoFrameContainer
          alignment={frameAlignment}
          borderColor={frameBorderColor}
          bgColor={frameBackgroundColor}
        >
          {frameImage}
        </PromoFrameContainer>
      )}
      <PromoContentContainer
        customStyles={contentContainerStyles}
        verticalAlign={verticalAlignContent}
        alignment={frameAlignment}
      >
        <Heading3>{title}</Heading3>
        {description &&
          (typeof description === 'string' ? (
            <Text fontSize="18px">{ReactHTMLParser(description)}</Text>
          ) : (
            description
          ))}
        {ctaButtons && (
          <PromoButtons alignment={frameAlignment}>{ctaButtons}</PromoButtons>
        )}
        {footerText && (
          <Text css="line-height: 1.3; padding-top 10px" fontSize="12px">
            {ReactHTMLParser(footerText)}
          </Text>
        )}
      </PromoContentContainer>
    </PromoContainer>
  );
};

export const PromoSection = styled(Section)`
    z-index: 0;
    padding-top: 40px;
    padding-bottom: 40px;
    .section__content {
      max-width: 950px;
    }
    @media (min-width: ${BREAKPOINT.LG}) {
      padding-top: 72px;
      padding-bottom: 72px;
    }
  }
`;

export default Promo;
